<template>
<div class="step__body">
  <div class="row">
    <div v-for="option in defaultOptions" :key="option.id" class="col-6 option option-color option--horizontal">
      <component :key="option.id" :option="option" :is="option.type + 'Option'"></component>
    </div>
  </div>
  <div v-if="specialOptions.length > 0" class="option__title mb-3">{{ step.label_special }}</div>
  <div v-if="specialOptions.length > 0" class="row">
    <div v-for="option in specialOptions" :key="option.id" class="col-6 option option-color option--horizontal">
      <component :key="option.id" :option="option" :is="option.type + 'Option'"></component>
    </div>
  </div>
</div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import SelectOption from './selectOptionRoof'
export default {
  name: 'ColorRoof',
  components: {
    SelectOption
  },
  setup () {
    const store = useStore()
    const step = computed(() => {
      return store.getters['configurator/currentStep']
    })
    const options = computed(() => {
      return store.getters['configurator/currentOptions']
    })
    const defaultOptions = computed(() => {
      return options.value.filter(o => o.group === 'default')
    })
    const specialOptions = computed(() => {
      return options.value.filter(o => o.group === 'special')
    })
    return {
      step,
      options,
      defaultOptions,
      specialOptions
    }
  }
}
</script>
